import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "../../utils/link"
import { navigate } from "gatsby-link"

import "./People.scss"

const PeopleInfo = (props) => {

  React.useEffect(() => {
    const position = parseInt(sessionStorage.getItem("position"))
    if (position) {
      window.scrollTo(0, position)
      sessionStorage.removeItem("position")
    }
    if (window.location.hash === "#services") {
      const section = document.querySelector(".services-section")
      if (section) {
        window.scrollTo(0, section.offsetTop)
      }
    }
  }, [])

  const openProfilePage = (e, member) => {
    e.preventDefault()
    console.log(member)
    sessionStorage.setItem("positionInitial", window.scrollY)
    navigate(`/people/${member.slug}`)
  }

  const { members } = useStaticQuery(graphql`
    query {
      members: allWpPerson(sort: {fields: [menuOrder], order: ASC}) {
        edges {
          node {
            title
            slug
            acf {
              role
              intro
              profilePicture {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              bio
            }
          }
        }
      }
    }
  `) 
  return (
    <div>
      { props.content && <div className="people__details" dangerouslySetInnerHTML={{ __html: props.content }} /> }
      <a href="people">&nbsp;</a>
      <div className="people__links">
        {members.edges.map(member => (
          <div key={member.node.slug}>
            <Link
              to="#"
              onClick={e => openProfilePage(e, member.node)}
              className="people__link"
            >
              {member.node.title}
            </Link>
            <p className="people__link_info">{member.node.acf.role}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PeopleInfo

